/* eslint-disable */
import { getBrandReviews } from "@/services/home";
import { SUCCESS_CODE } from "@/utils/constants";
import dynamic from 'next/dynamic';
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

const OverallRating = dynamic(() => import("./overall-rating"));
const Reviews = dynamic(() => import("./reviews"));

const ClientReviews = () => {
  const [googleReviewsData, setGoogleReviewsData] = useState([]);
  const [showReviews, setShowReviews] = useState(false);
  const [rating, setRating] = useState(0);
  const [numberOfReviews, setNumberOfReviews] = useState(0);
  useEffect(() => {
    const getGoogleReviews = async () => {
      const googleReviewsRes = await getBrandReviews();
      const googleReviewsData =
        googleReviewsRes.status === SUCCESS_CODE
          ? googleReviewsRes?.data
          : null;
      setGoogleReviewsData(googleReviewsData?.highlightedReviews);
      setRating(googleReviewsData?.averageRating);
      setNumberOfReviews(googleReviewsData?.numberOfReviews);
    };

    getGoogleReviews();
    displayReviews();
  }, []);

  const displayReviews = () => {
    // Hide reviews when no data found
    const getType = typeof googleReviewsData;
    if (getType === "string") {
      setShowReviews(false);
    } else if (googleReviewsData && googleReviewsData.length < 3) {
      setShowReviews(true);
    }
  };

  return (
    <>
      {showReviews ? (
        <section className="reviews">
          <div className="container">
            <h2 className="section-heading">
              <FormattedMessage id="client-reviews" />
            </h2>
            <div className="review-cards d-none d-lg-block d-md-none">
              <div className="row justify-content-center g-3">
                {googleReviewsData &&
                  googleReviewsData?.length &&
                  googleReviewsData?.map((data: any, index: any) => (
                    index < 3 && <Reviews key={index} data={data}></Reviews>
                  ))}
              </div>
            </div>
            {/* Tablet */}
            <div className="review-cards d-none d-lg-none d-md-block">
              <div className="row justify-content-center g-3">
                {googleReviewsData &&
                  googleReviewsData?.length &&
                  googleReviewsData?.map((data: any, index: any) => {
                    return (
                      index < 2 && <Reviews key={index} data={data}></Reviews>
                    );
                  })}
              </div>
            </div>
            {/* Mobile */}
            <div className="review-cards  d-block d-lg-none d-md-none">
              <div className="row justify-content-center g-3">
                {googleReviewsData &&
                  googleReviewsData?.length &&
                  googleReviewsData?.map((data: any, index: any) => {
                    return (
                      index === 0 && <Reviews key={index} data={data}></Reviews>
                    );
                  })}
              </div>
            </div>
            <OverallRating
              rating={rating}
              numberOfReviews={numberOfReviews}
            ></OverallRating>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default ClientReviews;
